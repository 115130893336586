import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "navlink"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Home ")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      (!_ctx.loggedIn)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/login",
            class: "navlink"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Log In ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.loggedIn)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_router_link, {
              class: "navlink",
              to: "/logout",
              onClick: _withModifiers(_ctx.logout, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Log Out ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createTextVNode(" | "),
            (_ctx.loggedIn)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/dashboard",
                  class: "navlink"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Dashboard ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.hasProject)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, {
                    to: "/view",
                    class: "navlink"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Core View ")
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.canCreateProject)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, {
                    to: "/createProject",
                    class: "navlink"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Create New Project ")
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_router_view)
  ], 64))
}