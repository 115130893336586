
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import ProjectEntry from "@/components/ProjectSelectionItem.vue";
import { useProjectStore } from "@/stores/firebaseProjectStore";

export default defineComponent({
  components: {
    ProjectEntry,
  },
  setup() {
    const { accessibleProjects: projects, canCreateProjects } = storeToRefs(useProjectStore());
    return {
      projects,
      canCreateProjects,
    };
  },
});
