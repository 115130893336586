import { mapErr, mapOk, Ok } from "./result.js";
import { matchFloat, parseAs } from "./parser.js";
export const parseLayoutStr = (val) => {
    return mapOk(v => [v], parseLayerStr(val));
};
export function parseLayerStr(s) {
    return mapErr(JSON.stringify, parseAs(s, {
        NAME: { match: /NAME:([^;]+)/i, f: (match) => Ok(match[1]) },
        SH: { match: /SH:(\d+(?:\.\d+)?)/i, f: matchFloat },
        EH: { match: /EH:(\d+(?:\.\d+)?)/i, f: matchFloat },
        SECTIONS: {
            matches: /(SECTION:(\d+),(\d+),(\d+),(\d+),([12]))|(POLYGON:([12]),((?:\d+,\d+)+));/ig,
            f: (matches) => {
                const sections = [];
                for (const match of matches) {
                    if (match[1]) {
                        sections.push({
                            sectionType: "SECTION",
                            x: parseInt(match[2]),
                            y: parseInt(match[3]),
                            width: parseInt(match[4]),
                            height: parseInt(match[5]),
                            type: match[6] == "1" ? SectionType.WALL : SectionType.OPENING,
                        });
                    }
                    else if (match[7]) {
                        sections.push({
                            sectionType: "POLYGON",
                            type: match[8] == "1" ? SectionType.WALL : SectionType.OPENING,
                            points: match[9].split(",").reduce(([points, x], s) => {
                                const n = parseInt(s);
                                if (x == null) {
                                    return [points, n];
                                }
                                else {
                                    points.push({ x, y: n });
                                    return [points, null];
                                }
                            }, [[], null])[0],
                        });
                    }
                }
                return Ok(sections);
            },
        },
    }));
}
export function getLayer(core, height, useFallback = true) {
    for (const layer of core) {
        if (layer.EH < height) {
            continue;
        }
        if (layer.SH < height && layer.EH > height) {
            return layer;
        }
    }
    if (useFallback) {
        return core[core.length - 1];
    }
    else {
        return null;
    }
}
export var SectionType;
(function (SectionType) {
    SectionType[SectionType["WALL"] = 1] = "WALL";
    SectionType[SectionType["OPENING"] = 2] = "OPENING";
})(SectionType || (SectionType = {}));
;
