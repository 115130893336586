export var ErrorCode;
(function (ErrorCode) {
    /** No error */
    ErrorCode[ErrorCode["None"] = 0] = "None";
    /** Data unavailable ‘!’ */
    ErrorCode[ErrorCode["Unavailable"] = 1] = "Unavailable";
    /** Light error ‘*’ */
    ErrorCode[ErrorCode["Light"] = 2] = "Light";
    /** Range error ‘#’ */
    ErrorCode[ErrorCode["Range"] = 3] = "Range";
    /** Calculation error ‘?’ */
    ErrorCode[ErrorCode["Calculation"] = 4] = "Calculation";
    /** Transmission error ‘~’ */
    ErrorCode[ErrorCode["Transmission"] = 5] = "Transmission";
    /** Data format error ‘^’ */
    ErrorCode[ErrorCode["Format"] = 6] = "Format";
    /** Mnemonic not found ‘%’ */
    ErrorCode[ErrorCode["NotFound"] = 7] = "NotFound";
    /** undefined ‘@’ */
    ErrorCode[ErrorCode["Undefined"] = 8] = "Undefined";
    /** EDM Blocked “Blocked” */
    ErrorCode[ErrorCode["Blocked"] = 9] = "Blocked";
    /** EDM Measurement Jump “Jumped” */
    ErrorCode[ErrorCode["Jumped"] = 10] = "Jumped";
    /** Shorter EDM Distance “Lowered” */
    ErrorCode[ErrorCode["Lowered"] = 11] = "Lowered";
})(ErrorCode || (ErrorCode = {}));
export function errorCodeText(error) {
    return [
        "",
        "!",
        "*",
        "#",
        "?",
        "~",
        "^",
        "%",
        "@",
        "Blocked",
        "Jumped",
        "Lowered",
    ][error];
}
/**
 * @param error either a base-10 number, or a string number, or character / string of the error text
 * @returns A the error, or -1 if the error code is invalid
 */
export function parseErrorCode(error) {
    let num;
    if (typeof error === "string") {
        num = parseInt(error, 10);
        if (Number.isNaN(num)) {
            return [
                "",
                "!",
                "*",
                "#",
                "?",
                "~",
                "^",
                "%",
                "@",
                "Blocked",
                "Jumped",
                "Lowered",
            ].indexOf(error);
        }
    }
    else {
        num = error;
    }
    return 0 <= num && num < 12 ? num : -1;
}
export function parseWithError(val, min, max) {
    const parts = val.split(":", 2);
    const errors = [];
    const v = parseFloat(parts[0]);
    if (isNaN(v)) {
        errors.push("Expected number, found: " + parts[0]);
    }
    else if ((min != undefined && min > v) || (max != undefined && max < v)) {
        errors.push(`Out of range. Expected ${min ?? ""} - ${max ?? ""}, found: ${parts[0]}`);
    }
    const e = parseErrorCode(parts[1]);
    if (e < 0) {
        errors.push("invalid error code: " + parts[0]);
    }
    if (errors.length > 0) {
        return errors.join("; ");
    }
    else {
        return [v, e];
    }
}
export * from "./periodic.js";
