export function isOk(r) {
    return r.ok;
}
export function isErr(r) {
    return !r.ok;
}
export function Ok(val) {
    return { ok: true, val };
}
export function Err(err) {
    return { ok: false, err: err };
}
export function mapOk(mapper, result) {
    return isOk(result) ? Ok(mapper(result.val)) : result;
}
export function flatMap(mapper, result) {
    return isOk(result) ? mapper(result.val) : result;
}
export function mapErr(mapper, result) {
    return isErr(result) ? Err(mapper(result.err)) : result;
}
export function or(fallback, result) {
    return isOk(result) ? result.val : fallback;
}
export function unwrap(result) {
    return or(undefined, result);
}
// type OkType<R extends Result<any, any>> = R extends Result<infer T, any> ? T : never;
// type ErrType<R extends Result<any, any>> = R extends Result<any, infer E> ? E : never;
// type OkTypes<Tuple extends [...any[]]> = {
//   [Index in keyof Tuple]: OkType<Tuple[Index]>;
// } & {length: Tuple['length']};
// type ErrTypes<Tuple extends [...any[]]> = {
//   [Index in keyof Tuple]: ErrType<Tuple[Index]>;
// } & {length: Tuple['length']};
// export function allOk<R extends Result<any, any>[], T, E>(mapper: ()...results: R): Result<T, E>
// export function andThen(f: ())
/**
 * Converts a list of Results into a Result of lists.
 * Does not short circuit, so will return either all the values if they are all ok,
 * or all the errors that occurred.
 */
export function unwrapArray(...results) {
    const [ok, err] = results.reduce(([ok, err], res) => {
        if (isOk(res)) {
            ok.push(res.val);
        }
        else {
            err.push(res.err);
        }
        return [ok, err];
    }, [[], []]);
    return err.length > 0 ? Err(err) : Ok(ok);
}
/**
 * Converts a { key: Result } object into a Result of the object.
 * Does not short circuit, so will return either the Ok object,
 * or an object of the errors that occurred (leaving out the ok properties).
 */
export function unwrapObj(resultObj) {
    const [ok, err] = Object.entries(resultObj).reduce(([ok, err], [key, res]) => {
        if (isOk(res)) {
            ok[key] = res.val;
        }
        else {
            err[key] = res.err;
        }
        return [ok, err];
    }, [{}, {}]);
    return Object.keys(err).length > 0 ? Err(err) : Ok(ok);
}
export default {
    Ok, Err,
    isOk, isErr,
    mapOk, mapErr, flatMap,
    unwrapArray, unwrapObj,
    or, unwrap,
};
