import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["projectSelectionLink", { selected: _ctx.isCurrent }]),
    href: _ctx.isCurrent ? undefined : `selectProject(${_ctx.projectId})`,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.selectProject && _ctx.selectProject(...args)), ["prevent"]))
  }, _toDisplayString(_ctx.projectName + (_ctx.isCurrent ? " (Currently Selected)" : "")), 11, _hoisted_1))
}