import { Err, Ok } from "./result.js";
function isMultiple(typeDef) {
    return "matches" in typeDef;
}
function isOptional(typeDef) {
    return Object.hasOwn(typeDef, "optionalDefault");
}
export function parseAs(source, props) {
    const [obj, paramErrors] = Object.entries(props)
        .reduce(([obj, paramErrors], [key, typeDef]) => {
        const maybe = (match) => match
            ? typeDef.f(match)
            : isOptional(typeDef)
                ? Ok(typeDef.optionalDefault)
                : Err("missing");
        const res = maybe(isMultiple(typeDef)
            ? source.matchAll(typeDef.matches)
            : typeDef.match.exec(source));
        if (res.ok) {
            obj[key] = res.val;
        }
        else {
            paramErrors[key] = res.err;
        }
        return [obj, paramErrors];
    }, [{}, {}]);
    return Object.keys(paramErrors).length > 0 ? Err(paramErrors) : Ok(obj);
}
export const matchInt = (match, group = 1) => {
    const val = parseInt(match[group]);
    return isNaN(val) ? Err("Expected integer, found: " + match[group]) : Ok(val);
};
export const matchFloat = (match, group = 1) => {
    const val = parseFloat(match[group]);
    return isNaN(val) ? Err("Expected number, found: " + match[group]) : Ok(val);
};
