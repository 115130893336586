import { parseDirection } from "../index.js";
import result, { Err, Ok } from "../result.js";
import { parseErrorCode } from "./index.js";
import { matchFloat, matchInt, parseAs } from "../parser.js";
export function parseDataIn(data) {
    const matchErrorVal = (group1 = 1, group2 = 2) => (match) => {
        const v = parseFloat(match[group1]);
        const e = parseErrorCode(match[group2]);
        const val = isNaN(v) ? Err("Expected number, found: " + match[group1]) : Ok(v);
        if (e < 0) {
            return Err("Invalid ErrorCode: " + e + (val.ok ? "" : "; " + val.err));
        }
        else {
            return result.mapOk(val => [val, e], val);
        }
    };
    const validate = (f, validator) => (match) => result.flatMap(val => (Array.isArray(val) ? result.mapOk(v => [v, val[1]], validator(val[0])) : validator(val)), f(match));
    const percentageEV = (group1 = 1, group2 = 2) => validate(matchErrorVal(group1, group2), val => val >= 0 && val <= 100 ? Ok(val) : Err("Percentage must be between 0 and 100, found: " + val));
    return result.mapErr(JSON.stringify, parseAs(data, {
        LT: { match: /LT=(\d+)/i, f: matchInt },
        DT: {
            match: /DT=(\d{4})(\d{2})(\d{2})/i,
            f: (match) => Ok(`${match[1]}-${match[2]}-${match[3]}`),
        },
        TM: { match: /TM=(\d{2}:\d{2}:\d{2})/i, f: (match) => Ok(match[1]) },
        SYS: { match: /SYS=(\d+)/i, f: matchInt },
        XPD: {
            match: /XPD=([0-3])/i,
            f: (match) => result.mapErr(num => "invalid direction: " + num, parseDirection(match[1])),
        },
        ESV1: { match: /ESV1=(\d+(?:\.\d)?)/i, f: matchFloat },
        ESV2: { match: /ESV2=(\d+(?:\.\d)?)/i, f: matchFloat },
        ESV3: { match: /ESV3=(\d+(?:\.\d)?)/i, f: matchFloat },
        ESV4: { match: /ESV4=(\d+(?:\.\d)?)/i, f: matchFloat },
        DAT1: { match: /DAT1=(\d+(?:\.\d)?)/i, f: matchFloat },
        DAT2: { match: /DAT2=(\d+(?:\.\d)?)/i, f: matchFloat },
        GND1: { match: /GND1=(\d+(?:\.\d)?)/i, f: matchFloat },
        GND2: { match: /GND2=(\d+(?:\.\d)?)/i, f: matchFloat },
        EDMDD: { match: /EDMDD=(\d+(?:\.\d)?):(\d+)/i, f: matchErrorVal() },
        EQ: { match: /EQ=(\d+):(\d+)/i, f: percentageEV() },
        ERF: { match: /ERF=(\d+):(\d+)/i, f: percentageEV() },
        XYTARGETS: {
            matches: /TID=(\d+),XC=(\d+),YC=(\d+),XSR=(-?\d+\.\d):(\d+),YSR=(-?\d+\.\d):(\d+),XST=(-?\d+\.\d):(\d+),YST=(-?\d+\.\d):(\d+),PL=(\d+\.\d):(\d+),TLER=(\d+\.\d):(\d+),TRF=(\d+\.\d):(\d+)/ig,
            f: (matches) => {
                const targetErrors = [];
                const targets = [];
                for (const match of matches) {
                    const target = result.unwrapObj({
                        TID: matchInt(match, 1),
                        XC: matchInt(match, 2),
                        YC: matchInt(match, 3),
                        XSR: matchErrorVal(4, 5)(match),
                        YSR: matchErrorVal(6, 7)(match),
                        XST: matchErrorVal(8, 9)(match),
                        YST: matchErrorVal(10, 11)(match),
                        PL: matchErrorVal(12, 13)(match),
                        TLER: percentageEV(14, 15)(match),
                        TRF: percentageEV(16, 17)(match),
                    });
                    if (target.ok) {
                        targets.push(target.val);
                    }
                    else {
                        targetErrors.push(target.err);
                    }
                }
                return targetErrors.length > 0 ? Err(targetErrors) : Ok(targets);
            },
        },
    }));
}
export function rename(logData) {
    return {
        logType: 3,
        datetime: new Date(`${logData.DT}T${logData.TM}`),
        systemId: logData.SYS,
        xPlusDir: logData.XPD,
        edmBoardHeight: logData.ESV1,
        edmBoardDatum: logData.ESV2,
        edmToTS: logData.ESV3,
        edmTSToDatum: logData.ESV4,
        rigDatum: logData.DAT1,
        tsDatum: logData.DAT2,
        rigGround: logData.GND1,
        tsGround: logData.GND2,
        edmDist: logData.EDMDD,
        edmQuality: logData.EQ,
        edmSignal: logData.ERF,
        targets: logData.XYTARGETS,
    };
}
