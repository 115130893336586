
import { useProjectStore } from "@/stores/firebaseProjectStore";
import router from "@/router";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    projectId: { type: String, required: true },
    name: { type: String, default: "" },
  },
  setup(props) {
    const store = useProjectStore();
    const selectProject = async (): Promise<void> => {
      if (store.selectedProjectId !== props.projectId) {
        await store.selectProject(props.projectId);
        router.push("/view");
      };
    };
    return {
      isCurrent: computed(() => store.selectedProjectId === props.projectId),
      projectName: computed(() => props.name.length > 0
        ? `${props.name} (${props.projectId})`
        : `${props.projectId} (Unnamed project)`),
      selectProject,
    };
  },
});
