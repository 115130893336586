
import { useProjectStore } from "@/stores/firebaseProjectStore";
import { getAuth, signOut } from "firebase/auth";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const store = useProjectStore();
    const loggedIn = ref(getAuth().currentUser != null);
    const hasProject = computed(() => loggedIn.value && store.selectedProjectId != null);
    getAuth().onAuthStateChanged(user => loggedIn.value = user != null);
    return {
      canCreateProject: computed(() => store.canCreateProjects),
      hasProject,
      loggedIn,
      logout: async () => {
        store.selectProject(null);
        await signOut(getAuth());
      },
    };
  },
});
