import {
  DocumentData, FirestoreDataConverter,
  QueryDocumentSnapshot, SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";
import { DatedLogEntry, DatedLogEntryDB, rename } from "./log/periodic.js";
import { ProjectInfo } from "./index.js";

export const projectInfoConverter: FirestoreDataConverter<ProjectInfo> = {
  toFirestore: function (projectInfo: WithFieldValue<ProjectInfo>): DocumentData {
    return projectInfo;
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    _options?: SnapshotOptions,
  ): ProjectInfo {
    return {
      projectName: snapshot.get("projectName") ?? snapshot.get("name") ?? snapshot.get("DISPLAYNAME"),
      completed: snapshot.get("completed") ?? false,
      dateCreated: (snapshot.get("creationDate") as Timestamp | undefined)?.toDate() ?? new Date(0),
      coreLayout: snapshot.get("coreLayout") ?? snapshot.get("LAYOUT"),
    };
  },
};

export const logDataConverter: FirestoreDataConverter<DatedLogEntryDB> = {
  toFirestore: function (logEntry: WithFieldValue<DatedLogEntryDB>): DocumentData {
    return {
      DT_TM: Timestamp.fromDate(new Date(`${logEntry.DT}T${logEntry.TM}`)),
      ...logEntry,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions,
  ): DatedLogEntryDB {
    const timestamp = ((snapshot.get("TIMESTAMP_ADDED") ?? snapshot.get("TIMESTAMP")) as Timestamp).toDate();
    const dateISO = timestamp.toISOString();
    const logType = snapshot.get("LT") ?? snapshot.get("LOGTYPE");
    switch (logType) {
      case 3:
      default:
        return {
          TIMESTAMP_ADDED: timestamp,
          LT: logType,
          DT: snapshot.get("DT") ?? dateISO.slice(0, 10),
          TM: snapshot.get("TM") ?? dateISO.slice(11, 19),
          SYS: snapshot.get("SYS") ?? 0,
          XPD: snapshot.get("XPD") ?? 0,
          ESV1: snapshot.get("ESV1") ?? 0,
          ESV2: snapshot.get("ESV2") ?? 0,
          ESV3: snapshot.get("ESV3") ?? 0,
          ESV4: snapshot.get("ESV4") ?? 0,
          DAT1: snapshot.get("DAT1") ?? snapshot.get("RIGDATUM"),
          DAT2: snapshot.get("DAT2") ?? snapshot.get("TSDATUM"),
          GND1: snapshot.get("GND1") ?? snapshot.get("RIGGROUND"),
          GND2: snapshot.get("GND2") ?? snapshot.get("TSGROUND"),
          EDMDD: snapshot.get("EDMDD") ?? snapshot.get("EDMDIST"),
          EQ: snapshot.get("EQ") ?? snapshot.get("EDMQUALITY"),
          ERF: snapshot.get("ERF") ?? snapshot.get("RADIORSSI"),
          XYTARGETS: snapshot.get("XYTARGETS"),
        };
    }
  },
};

export const logDataConverterFriendly: FirestoreDataConverter<DatedLogEntry> = {
  toFirestore: function (_logEntry: WithFieldValue<DatedLogEntry>): DocumentData {
    throw new Error("Function not implemented.");
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions,
  ): DatedLogEntry {
    const timestamp = ((snapshot.get("TIMESTAMP_ADDED") ?? snapshot.get("TIMESTAMP")) as Timestamp).toDate()
    const logType = snapshot.get("LT") ?? snapshot.get("LOGTYPE");
    switch (logType) {
      case 3:
      default:
        return {
          TIMESTAMP_ADDED: timestamp,
          ...rename(logDataConverter.fromFirestore(snapshot, options)),
        };
    }
  },
};
