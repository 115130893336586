
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const email = ref("");
    const password = ref("");
    const errorMsg = ref("");
    const alertHidden = ref(true);
    // const moveToRegister = () => {
    //   router.push("/register");
    // };
    return {
      email,
      password,
      errorMsg,
      alertHidden,
      login: async () => {
        if (email.value.length <= 0) {
          alertHidden.value = false;
          errorMsg.value = "Email field is blank!";
        } else if (password.value.length <= 0) {
          alertHidden.value = false;
          errorMsg.value = "Password field is blank!";
        } else {
          try {
            console.log("Attempting login in as:", email.value);
            await signInWithEmailAndPassword(getAuth(), email.value, password.value);
          } catch (error: any) {
            console.log("Login error:", error.message);
            alertHidden.value = false;
            errorMsg.value = error.message;
          };
        }
      },
      // moveToRegister,
    };
  },
});
