import { createRouter, createWebHistory,
  RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw,
} from "vue-router";
import { getAuth } from "firebase/auth";

import Home from "@/views/Home.vue";
import Login from "@/views/LoginView.vue";
import Dashboard from "@/views/Dashboard.vue";
import { useProjectStore } from "@/stores/firebaseProjectStore";

type NavGuard =
  (to: RouteLocationNormalized, from: RouteLocationNormalized) => void | RouteLocationRaw | boolean;

const loginRequired: NavGuard = (to, from) => {
  if (!getAuth().currentUser) {
    // alert("You must be logged in to access this area");
    return "/login";
  }
};

const projRequired: NavGuard = (to, from) => {
  if (useProjectStore().selectedProjectId == null) {
    // alert("Select a project to access this area");
    return "/dashboard";
  }
};

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Log In",
    component: Login,
    beforeEnter() {
      if (getAuth().currentUser) {
        alert("You're already logged in");
        return false;
      }
    },
  },
  {
    path: "/logout",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: [loginRequired],
  },
  {
    path: "/createProject",
    name: "CreateProject",
    component: () => import(/* webpackChunkName: "newproject" */ "@/views/CreateProject.vue"),
    beforeEnter: [loginRequired, (to, from) => useProjectStore().canCreateProjects],
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: Home,
  // },
  {
    path: "/view",
    name: "View",
    // route level code-splitting
    // this generates a separate chunk (jobstructure.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobstructure" */ "@/views/CoreView.vue"),
    beforeEnter: [loginRequired, projRequired],
  },
  // {
  //   path: "/logs-table",
  //   name: "Logs (Table view)",
  //   // route level code-splitting
  //   // this generates a separate chunk (logs.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "logs" */ "../views/LogTableView.vue"),
  //   beforeEnter: [loginRequired, projRequired],
  // },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const auth = getAuth();

//   if (auth.currentUser && to.path == "/login") {
//     alert("You're already logged in");
//     return from;
//   }
//   if (!auth.currentUser && to.matched.some(record => record.meta.authRequired)) {
//     // alert("You must be logged in to access this area");
//     return "/login";
//   }

//   next();
// });
router.afterEach((to, _from) => {
  document.title = to.meta.title as string || "Zed Projects";
});

export function initAuthRoutes(): void {
  getAuth().onAuthStateChanged(async (user) => {
    if (user != null) {
      console.log(`Logged in as ${user.displayName ?? user.email}`);
      router.push("/dashboard");
    } else if (router.currentRoute.value.meta.authRequired) {
      await router.push("/login");
    }
  });
}

export default router;
