import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import { router, initAuthRoutes } from "./router";

import { initializeApp } from "firebase/app";

initializeApp({
  apiKey: "AIzaSyDJTRR_QMLxc9SgZdJlZigc_uatw_gbDfk",
  authDomain: "zed-projects-dev.firebaseapp.com",
  databaseURL: "https://zed-projects-dev.firebaseio.com",
  projectId: "zed-projects-dev",
  storageBucket: "zed-projects-dev.appspot.com",
  messagingSenderId: "932673182830",
  appId: "1:932673182830:web:0b062da78a6328c49dda7f",
  measurementId: "G-VB0PKHD70M",
});

initAuthRoutes();

createApp(App).use(createPinia()).use(router).mount("#app");
