import { Err, Ok } from "./result.js";
export var Direction;
(function (Direction) {
    Direction[Direction["LEFT"] = 0] = "LEFT";
    Direction[Direction["RIGHT"] = 1] = "RIGHT";
    Direction[Direction["UP"] = 2] = "UP";
    Direction[Direction["DOWN"] = 3] = "DOWN";
})(Direction || (Direction = {}));
export function parseDirection(val) {
    let i = typeof val === "number" ? val : parseInt(val, 10);
    return isNaN(i)
        ? Err(NaN)
        : i < 0 || i > 3
            ? Err(i)
            : Ok(i);
}
// import { JobConfig } from "./jobcfg.js";
// export * as Job from "./jobcfg.js";
export * from "./coreShape.js";
export * from "./log/index.js";
export function isProjectID(id) {
    switch (typeof id) {
        case "string":
            return /^[0-9]{6}$/.test(id);
        case "number":
            return 0 < id && id <= 999_999;
        default:
            return false;
    }
}
