import { getAuth } from "firebase/auth";
import { defineStore } from "pinia";
import { collection, doc, getFirestore, onSnapshot, Unsubscribe } from "firebase/firestore";
import { computed, ref, watch } from "vue";
import { ProjectInfo } from "@common/index";
import { projectInfoConverter } from "@common/firestoreConversion";
import router from "@/router";

export const useProjectStore = defineStore("projects", () => {
  const db = getFirestore();
  let userSubscribe: Unsubscribe | null = null;
  const isAdmin = ref(false);
  let adminProjectsSubscription: Unsubscribe | null = null;
  const groupSubscriptions = new Map<string, Unsubscribe>();
  const projectSubscriptions = new Map<string, Unsubscribe>();
  const email = ref(null as string | null);
  const displayName = ref(null as string | null);
  const userProjects = ref([] as string[]);
  const groupProjects = ref({} as { [groupId: string]: string[] });
  const accessibleProjectIds = computed(() => {
    const set = new Set<string>();
    userProjects.value.forEach(id => set.add(id));
    Object.values(groupProjects.value).flatMap(val => val).forEach(id => set.add(id));
    return set;
  });
  const accessibleProjects = ref({} as { [projectId: string]: ProjectInfo });
  watch(accessibleProjectIds, (newVal, oldVal) => {
    if (!isAdmin.value) {
      // Unsubscribe from removed projects
      for (const projectId of [...oldVal].filter(v => !newVal.has(v))) {
        if (projectSubscriptions.has(projectId)) {
          projectSubscriptions.get(projectId)!();
          projectSubscriptions.delete(projectId);
        }
      }
      // Subscribe to added projects
      for (const projectId of [...newVal].filter(v => !oldVal.has(v))) {
        projectSubscriptions.set(projectId, onSnapshot(
          doc(db, "projects", projectId).withConverter(projectInfoConverter),
          async (snapshot) => {
            accessibleProjects.value[projectId] = snapshot.data() as ProjectInfo;
          },
        ));
      }
    }
  });
  const selectedProjectId = ref(null as string | null);
  watch(selectedProjectId, (val, oldVal) => {
    console.debug(`Changing selected project from ${oldVal} to ${val}`);// Debug XXX
    if (val != null && !Object.hasOwn(accessibleProjects.value, val)) {
      selectedProjectId.value = oldVal;
    }
  });
  const selectedProject = computed(() => {
    if (selectedProjectId.value != null
      && Object.hasOwn(accessibleProjects.value, selectedProjectId.value)) {
      return accessibleProjects.value[selectedProjectId.value];
    }
    return null;
  });
  /**
  * Set the current projectId. Use `null` to close the current project
  * @returns true if sucessful, false if unsuccessful (project unavailable)
  */
  const selectProject = (projectId: string | null): boolean => {
    if (projectId === null || Object.hasOwn(accessibleProjects.value, projectId)) {
      selectedProjectId.value = projectId;
      return true;
    } else {
      return false;
    }
  };

  function unsubscribeAll(): void {
    if (adminProjectsSubscription != null) {
      adminProjectsSubscription();
      adminProjectsSubscription = null;
    }
    projectSubscriptions.forEach(unsub => unsub());
    projectSubscriptions.clear();
    groupSubscriptions.forEach(unsub => unsub());
    groupSubscriptions.clear();
    if (userSubscribe != null) {
      userSubscribe();
      userSubscribe = null;
    }
    isAdmin.value = false;
  }
  const auth = getAuth();
  auth.beforeAuthStateChanged(_ => unsubscribeAll());
  auth.onAuthStateChanged(async (user) => {
    // unsubscribeAll();
    isAdmin.value = false;
    userProjects.value = [];
    groupProjects.value = {};
    accessibleProjects.value = {};
    selectedProjectId.value = null;
    if (user != null) {
      email.value = user.email;
      displayName.value = user.displayName ?? user.email;
      userSubscribe = onSnapshot(
        doc(db, "users", user.uid),
        (userDoc) => {
          isAdmin.value = "admin" === userDoc.get("group");
          if (isAdmin.value) {
            adminProjectsSubscription = onSnapshot(
              collection(db, "projects").withConverter(projectInfoConverter),
              snapshot => snapshot.docChanges().forEach((change) => {
                const projDoc = change.doc;
                if (change.type === "removed") {
                  delete accessibleProjects.value[projDoc.id];
                } else {
                  accessibleProjects.value[projDoc.id] = projDoc.data() as ProjectInfo;
                }
              }),
            );
          } else {
            userProjects.value = Object.keys(userDoc.get("projects") ?? {}); //TODO: check?
            const groups: string[] = userDoc.get("groups") ?? [];
            if (groups.length < 1) {
              const group = userDoc.get("group") ?? "";
              if (group.length > 0) {
                groups.push(group);
              }
            }
            for (const groupId of groups) {
              groupSubscriptions.set(groupId, onSnapshot(
                doc(db, "groups", groupId),
                (groupDoc) => {
                  groupProjects.value[groupId] = groupDoc.get("projects") ?? [];
                },
              ));
            }
          }
        },
      );
      router.push("/dashboard");
    } else {
      email.value = null;
      displayName.value = null;
      router.push("/login");
    }
  });

  return {
    email,
    displayName,
    accessibleProjects,
    selectedProject,
    selectedProjectId: computed({
      get: () => selectedProjectId.value,
      set: selectProject,
    }),
    selectProject,
    canCreateProjects: computed(() => isAdmin.value), //TODO: more in-depth
  };
});
